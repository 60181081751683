.stepOpen, .stepClosed{
  --margin: .5rem;
  margin-top: var(--margin);
  margin-bottom: var(--margin);

  overflow: hidden;
  transform: scale(var(--scale));
  z-index: 0;
}

.stepOpen{
  --margin: 1rem;
}

.stepClosed{
  /* --margin: .5rem; */
  --scale: 1;
}

.stepOpen .details{
  --max-height: 25rem;
} 
.stepClosed .details{
  --max-height: 0rem;
}
.details{
  max-height: var(--max-height);
  transition: all .3s;
  overflow: hidden
}

.panel{
  --height: 7rem;
  height: calc(100vh - var(--height));
  transition: all .2s ease-in-out;
  width: calc(100vw - 10px);
  left: 5px;
  right: 5px;
  top: var(--height);
}

@media (min-width: 768px){
  .panel{
    left: auto;
    right: auto;
    --height: 10rem;
  }

}
.panelClose{
  transform: translateY(0);
  overflow: auto;
}
.panelOpen{
  transform: translateY(calc(100vh - var(--height) - 62px));
}

.added{
  animation: in-the-basket 1s 1 cubic-bezier(0.19, -0.11, 0.03, 0.98);
  position: relative;
  z-index: 100;
}

.selected{
  box-shadow: 0 0 1px 1px var(--blue);
}
@keyframes in-the-basket{
  0%{
    transform: translateX(0) scale(1) ;
  }
  70%{
    transform: translateX(100vw) scale(.3) ;
    opacity: 1;
  }
  71%{
    transform: translateX(0) scale(.7) ;
    opacity: 0;
  }
  100%{
    transform: translateX(0) scale(1) ;
    opacity: 1;
  }
}
