.detailsRow{
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
}

.detailsRow>div{
  flex-grow: 1;
}
.detailsRow>div:first-child{
  flex-grow: 2;
}

body .qtyInput input{
  width: auto!important;
}