.grid{
  display: grid;
  grid-template: auto 1fr / 250px 1fr;
  grid-gap: 1.5rem .5rem ;
  overflow-y: hidden;
}

@media (max-width: 768px){
  .searchContainer{
    padding: 5px 0;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
}

.searchContainer{
  grid-column: 1 / 3;
}

.categoryContainer{
  padding: 1rem 3rem 1rem 1rem;
  background: var(--light-grey);
  position: relative;
  margin-bottom: .5rem;
}
.categoryContainer:hover{
  background-color: var(--brand-color);
  cursor:pointer;
}

.selected{
  background-color: var(--brand-color);
}

.selected:after{
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--brand-color);
  position: absolute;
  top: 1rem;
  right: 0;
  transform: translateX(100%);
}




table tr{
  vertical-align: top;
}



.hasSelectedStep .step:not(.selected){
  opacity: .3;
}
.step {
  transform: scale(1);
  transition: transform .1s ease-in-out;
}
.stepSelected {
  background: white;
  transform: translateY(-5px) scale(1.05);
  opacity: 1!important;
}
.stepSelected.bottom{
  animation: grow .1s 1 forwards ease-in-out;
}
.stepSelected td{
  border-style: solid;
  border-color: var(--brand-color);
  border-width: 0;
  padding: 1rem 1rem;
}
.stepSelected td:first-child{
  border-left-width: 1px;
}
.stepSelected td:last-child{
  border-right-width: 1px;
}
.stepSelected.top td{
  border-top-width: 1px;
}
.stepSelected.bottom td{
  border-bottom-width: 1px;
}

@keyframes grow{
  from{ transform: translateY(0px) scale(1);}
  to{ transform: translateY(-5px) scale(1.05);}
}