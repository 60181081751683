@import url('https://fonts.googleapis.com/css?family=Roboto|Archivo+Narrow&display=swap');
:root{
  --brand-color: #FFCC33;
  --yellow: var(--brand-color);
  --bg-yellow: #FFF9EA;
  --blue: #7774FF;
  --light-grey: #F5F5F5;
  --dark-grey: #585858;
  --border-grey: #E5E5E5;
  --bg-grey: #F5F5F5;
  --color-1: #FC7753;
  --color-2: #66D7D1;
  --white: #FFF;
  --icon-grey: #A9A9A9;
}

html{
  font-size: 16px;
}
body {
  margin: 0;
  font-family:  "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-title{
  font-family: Archivo Narrow;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p{
  margin-top: 0;
}

.container{
  max-width: 960px;
  width: 100%;
}
@media (max-width: 1000px){
  .container{
    padding-left: 15px;
    padding-right: 15px;
  }
}

table{
  border-collapse: separate;
  border-spacing:0;
}

* {
  box-sizing: border-box;
}

.striped{
  background-color: repeating-linear-gradient(
    45deg,
    black,
    black 10px,
    var(--brand-color) 10px,
    var(--brand-color) 20px
  );
}
.striped-table tr:nth-child(even):not(.ignore-stripe){
  background: var(--light-grey);
}

@media (min-width: 768px){
  [class^="grid-"]{
    display: grid;
    grid-gap: 1rem;
  }
  .grid-2{
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-3{
    grid-template-columns: repeat(3, 1fr);
  }
}

.separator{
  position: relative;
}
.separator:after{
  content: '';
  display: inline-block;
  width: 100%;
  border-bottom: solid 1px var(--border-grey);
  position: absolute;
  top: 50%;
}

.light-bottom-border{
  border-bottom: solid 1px var(--border-grey);
}
.light-top-border{
  border-top: solid 1px var(--border-grey);
}

.step-row:last-child > *{
  border-bottom: none;
}

.note-container{
  display: flex;
  background-color: var(--background-grey);
  padding: 1rem 1rem .25rem;
}

.note-container > span {
  color: var(--blue);
  font-size: .875rem;
  display: inline-block;
  margin-right: .5rem;
  padding-top: 2px;
}
.note-container:not(.no-input) > span {
  padding-top: 8px;
}

.contents{
  display: contents;
}

.contents > * {
  background-color: inherit;
}

sup{
  line-height: 0;
}

.draft-version{
  border: 1px solid var(--blue);
  border-radius: 5px;
}

a > svg{
  color: var(--icon-grey);
}


.ReactCollapse--collapse {
  /* transition: height 250ms; */
}