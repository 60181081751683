.App {
  min-height: 100vh;
  min-width: 100vw;
  padding: 0rem 0rem;
}


tr.underlined td{
  border-bottom: var(--border-grey) solid 1px;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid var(--border-color, rgba(0, 0, 0, 0.42))!important;
  }
  
.MuiInput-underline:before{
  border-bottom: 1px solid var(--border-color, rgba(0, 0, 0, 0.42))!important;
  
}