@media (min-width: 767px){
  .grid{
    display: grid;
    grid-gap: 0 0;
    grid-template-columns: 1fr auto repeat(3, 8rem);
    /* grid-template-columns: desc qty price total; */
    /* 
    no
    desc
    qty
    price
    total
    */
    grid-template-rows: auto;
    position: relative;
    --last-col: 7;
  }
  .grid.grid5cols{
    --last-col: 6;
    grid-template-columns: 3ch 1fr repeat(3, 8rem);
  }

  .gridItem{
    padding: 1rem .5rem;
  }
}

.th{
  background: white;
  font-size: .875rem;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-self: flex-start;
  padding: .5rem;
  color: var(--dark-grey);
  vertical-align: middle;
}
.th, .line{
  position: sticky;
  top: 0;
}
.grid .line{
  grid-column: 1 / var(--last-col);
  border-bottom: 2px solid black;
  margin-bottom: 1rem;
  top: calc(2rem + 0px);
  padding-top: 0;
}

.gridContainer:nth-child(odd){
  background-color: var(--light-grey);
}

.spanAll{
  grid-column: 1 / var(--last-col);
}

.gridCategory{
  padding: .5rem 1rem .5rem .5rem;
  border-bottom: 2px solid var(--yellow);
  display: inline-block;
  grid-column: 1 / var(--last-col);
  margin-top: 1rem;
}

@media print {
  .gridCategory{
    background-color: transparent;
    font-weight: bold;
    border-bottom: none;
    grid-column: 2 / var(--last-col);
    margin-top: 14px;
    padding: 2px 0 2px .5rem;
  }
  .th{
    padding-top: 0;
    padding-bottom: 0;
    position: static!important;
  }
}

[class*="print"] .th{
  padding-top: 0;
  padding-bottom: 0;
}

[class*="print"] .gridCategory{
  padding: 0;
  padding: 2px 0 2px .5rem;
  background-color: transparent;
  font-weight: bold;
  border-bottom: none;
  grid-column: 2 / var(--last-col);
  margin-top: 14px;
}

.contents:first-child .gridCategory{
  margin-top: 0;
}