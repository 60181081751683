
@media print{
  html, body{
    font-size: 12px;
  }
  .no-print, .MuiSnackbar-root{
    display: none!important;
  }
  .room-title{
    font-size: 14px;
  }
}
.print .no-print{
  display: none;
}

.print .note-container{
  padding: 0!important;
  background: none;
}

.print .step-row > *{
  padding-top: 2px;
  padding-bottom: 2px;
}

.print .room-title{
  font-size: 16px;
}

.print [class*="room-0"] .table-header{
  /* display: none; */
}
.print .table-header{

}